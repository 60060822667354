import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Navigate, createBrowserRouter, RouterProvider } from "react-router-dom";
import _ from "lodash";
import Axios from "axios";

import Login from "./containers/Login";
import Dashboard from "./containers/Dashboard";
import RegistrationV2 from "./containers/RegistrationV2";
import RegistrationCustomerV2 from "./containers/RegistrationV2/Customer";
import ForgotPassword from "containers/ForgotPassword";
import ForgotSecurityPin from "containers/ForgotSecurityPin";
import { getItem } from "./utils/tokenStore";
import getDomainURL from "utils/api";

import { getProfile } from "actions/profile";
import PaymentFailed from "containers/Registration/PaymentFailed";
import PaymentSuccessful from "containers/Registration/PaymentSuccessful";
import PaymentPending from "containers/Registration/PaymentPending";
import { MainNav } from "components/Menu/VerticalNavWrapper/dataMainNav";
import TechBaseText from "DisplayCoreV2/Text";
import TechBaseConstruction from "DisplayCoreV2/Result/construction";
import RouterWrapper from "containers/Dashboard/RouterWrapper";
import { processSite } from "utils/host";

const shouldRevalidate = ({ currentUrl, nextUrl }) => {
  return currentUrl.pathname !== nextUrl.pathname;
};

const CustomRouter = (props) => {
  const [permission, setPermission] = useState({
    can_create: false,
    can_delete: false,
    can_read: false,
    can_update: false
  })
  useEffect(() => {
    if (getItem("TECHBASE_TOKEN")) {
      props.getProfile();
      getAccessPermission(_.find(flattenMainNav(MainNav, props.permissions)).id)
    }
  }, []);

  useEffect(() => {
    if (getItem("TECHBASE_TOKEN"), props.permissions?.length > 0) {
      getAccessPermission(_.find(flattenMainNav(MainNav, props.permissions))?.id)
    }
  }, [window.location.pathname, getItem("TECHBASE_TOKEN"), props.permissions])

  const flattenMainNav = (arr, permissions) => {
    let result = [];

    const flatten = (item, access) => {
      const module = _.find(access, { KeyName: item.module });
      if (item.disabled || _.isEmpty(module)) {
        return; // Skip this item and its content if it is disabled
      }
      item.id = module.MenuID;
      if (item.content && Array.isArray(item.content)) {
        item.content.forEach((child) => flatten(child, module.ChildItems));
      } else {
        result.push(item);
      }
    };

    arr.forEach((child) => flatten(child, permissions));

    return result;
  };

  const getAccessPermission = async (MenuID) => {
    const defaultPermission = {
      can_create: false,
      can_delete: false,
      can_read: false,
      can_update: false
    };
    let response;
    if (!getItem("IMPERSONATE_TOKEN") && !getItem("TECHBASE_TOKEN")) {
      return defaultPermission;
    }
    Axios.defaults.headers = {
      "Access-Control-Allow-Origin": "*",
      Site: processSite(window.location.host)
    };

    if (!_.isEmpty(props.profile)) {
      response = await Axios.post(`${getDomainURL()}/menu/getUserModuleAccess`, {
        roleID: props.profile.RoleID?.toString(),
        menuID: MenuID?.toString()
      });
    }
    setPermission(response?.data?.Data ?? defaultPermission)
    return response?.data?.Data ?? defaultPermission;
  };

  const router = createBrowserRouter([
    { path: "/login", element: <RouterWrapper component={(props) => <Login {...props} />} data={props} /> },
    {
      path: "/forgot-password",
      element: <RouterWrapper component={(props) => <ForgotPassword {...props} />} data={props} />
    },
    {
      path: "/forgot-security-pin",
      element: <RouterWrapper component={(props) => <ForgotSecurityPin {...props} />} data={props} />
    },
    {
      path: "/register/payment_successful/*",
      element: <RouterWrapper component={(props) => <PaymentSuccessful {...props} />} data={props} />
    },
    {
      path: "/register/payment_pending/*",
      element: <RouterWrapper component={(props) => <PaymentPending {...props} />} data={props} />
    },
    {
      path: "/register/payment_failed/*",
      element: <RouterWrapper component={(props) => <PaymentFailed {...props} />} data={props} />
    },
    { path: "/register", element: <RouterWrapper component={(props) => <RegistrationV2 {...props} />} data={props} /> },
    {
      path: "/register-customer",
      element: <RouterWrapper component={(props) => <RegistrationCustomerV2 {...props} />} data={props} />
    },
    {
      path: "/dashboard",
      element: <RouterWrapper component={(props) => <Dashboard {...props} />} data={props} />,
      children: flattenMainNav(MainNav, props.permissions).map((route) => {
        const url = route.to.replace("/dashboard/", "");
        return {
          ...(url ? { path: route.to.replace("/dashboard/", "") } : { index: true }),
          element: _.isUndefined(route.render) ? (
            <div>
              <TechBaseText Tag="h3" content={`{{${route.label}}}`} />
              <TechBaseConstruction />
            </div>
          ) : (
            <RouterWrapper component={props => route.render({...props, permission: permission})} data={props} />
          )
        };
      })
    },
    {
      path: "/dashboard/*",
      element: <Navigate to="/dashboard" />
    },
    { path: "*", element: <Navigate to="/login" /> }
  ]);

  return <RouterProvider router={router} />;
};

const mapStateToProps = (state) => ({
  data: state,
  profile: state.ProfileReducer.profile,
  permissions: state.ProfileReducer.permissions
});
export default compose(connect(mapStateToProps, { getProfile }))(CustomRouter);
