import React from "react"
import cx from "classnames"
import ReactCSSTransitionGroup from "react-addons-css-transition-group"
import { connect } from "react-redux"

import MobileMenu from "../Menu/MobileMenu"
import HeaderLogo from "./Logo"
import Userbox from "./Userbox"
import LanguageSwitcher from '../LanguageSwitcher'
import ShoppingCart from "./ShoppingCart"
import { applyColor } from 'utils/apply-color'
import UpgradeButton from "./UpgradeButton"
import { getPermissions } from 'utils/permissionChecker'

const Header = ({ 
  enableMobileMenuSmall, 
  enableHeaderShadow, 
  history, 
  colors, 
  windowHash, 
  profile, 
  clientInfo,
  siteModules,
  permissions
 }) => {
  return (
    <>
      <ReactCSSTransitionGroup
        component="div"
        className={cx( `app-header ${ clientInfo.name }`, {
          "header-shadow": false,
        })}
        style={{ backgroundColor: applyColor( '$header', colors )}}
        transitionName="HeaderAnimation"
        transitionAppear={true}
        transitionAppearTimeout={1500}
        transitionEnter={false}
        transitionLeave={false}
      >
        <MobileMenu/>
        <div className="app-header__heading">
          <HeaderLogo />
          <div className="app-header__page-name"></div>
        </div>
        <div
          className={cx("app-header__content", {
            "header-mobile-open": enableMobileMenuSmall,
          })}
        >
          <div className="app-header-content__left"></div>
          <div className="app-header-content__right">
            <LanguageSwitcher buttonClass={ 'btn-switch-lang btn-primary' } from={'header'}/>
            { windowHash.indexOf('checkout') === -1 && <ShoppingCart windowHash={ windowHash }/> }
            <Userbox history={history} />
          </div>
        </div>
      </ReactCSSTransitionGroup>
    </>
  )
}

const mapStateToProps = state => ({
  enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
  closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
  enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
  colors: state.ClientReducer.clientInfo.styles?.colors,
  profile: state.ProfileReducer.profile,
  siteModules: state.ClientReducer.siteModules,
  permissions: state.ProfileReducer.permissions,
  clientInfo: state.ClientReducer.clientInfo
})

export default connect( mapStateToProps )( Header )
