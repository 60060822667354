import React from 'react'
import _ from 'lodash'
import { Button, ConfigProvider } from 'antd'
import { connect } from "react-redux"

import CoreRenderFunction from 'DisplayCore'
import { applyColor } from 'utils/apply-color'
import { checkIfComponentDisabled, getContraColor } from 'utils'
import './index.scss'

const TechBaseButton = props => {
  let {
    href,
    size,
    style,
    shape,
    children,
    className,
    htmlType,
    buttonType,
    actionKey,
    param,
    isActiveKey,
    activeStyle,
    isDisabled,
    danger,
    target
  } = props.item

  const checkIsDisabled = () => {
    if( isDisabled ) {
      return checkIfComponentDisabled( props.disabledComponent, props.item )
    }
    return false
  }

  return(
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: applyColor(`$primary`)
        },
      }}
    >
      <Button
        size={ size }
        danger={ danger }
        htmlType={ htmlType }
        type={ buttonType }
        target={ target }
        style={{
          ...applyColor( { ...style, ...isActiveKey && props[ isActiveKey ] === true ? !_.isEmpty(activeStyle) ? activeStyle : {} : {} }, props.colors ),
          color: applyColor( style?.color || getContraColor( applyColor( style?.backgroundColor, props.color ) ), props.colors )
        }}
        className={ className }
        shape={ shape }
        href={ href }
        onClick={ () => {
          if ( typeof props.onClickAction === 'function' ) {
            props.onClickAction( actionKey, ...( param ? param.map( val => props[ val ] || val ) : [] ) ) 
          }
        }}
        disabled={ checkIsDisabled() }
      >
        {
          children?.map( child => <CoreRenderFunction {...props} item={ child } />)
        }
      </Button>
    </ConfigProvider>
  )
}

const mapStateToProps = state => ({
  colors: state.ClientReducer.clientInfo.styles?.colors
})

export default connect( mapStateToProps )( TechBaseButton )