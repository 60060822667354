export const processSite = () => {
  return 'jombiz-ecomm-staging.junomax.co'
}

export const EcommURL = param => {
  let tmp = ''
  if( ( param.indexOf( 'localhost' ) !== -1 ) || param.indexOf( '127.0.0.1' ) !== -1 ) {
    tmp = 'http://localhost:4051'
  } else {
    tmp = `https://${processSite()}`
  }
  return tmp
}