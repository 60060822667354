import React, { Component } from "react"
import { connect } from "react-redux"

class Logo extends Component {
  render = () => {
    return (
      <div className="app-header__logo" style={{ paddingTop: "10px" }}>
        <img src="/logo.png" alt="logo" style={{ height: "100%" }} />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  logoImage: state.ClientReducer.clientInfo.logo_image_url
})

export default connect( mapStateToProps )( Logo )
