import React, { Component } from "react";
import Axios from "axios";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import getDomainURL from "utils/api";
import { storeItem } from "utils/tokenStore";
import { getProfile } from "actions/profile";
import { handleErrorMsg } from "utils";
import { getCipherHeader } from "utils/cipher-text";
import { processSite } from "utils/host";
import { setUserProfile } from "reducers/profile";
import { requestError } from "utils/requestHandler";

const HOC = (WrapperComponent) => {
  class WithHOC extends Component {
    state = {
      attempRemain: 0,
      loading: false,
      email: "",
      password: "",
      statusModal: false,
      showPassword: false,
    };

    onChangeLoginHOC = (key, val) => this.setState({ [key]: val });

    loginRequest = (data) => {
      this.setState({ loading: true }, () => {
        Axios.post(`${getDomainURL()}/login`, data, {
          headers: {
            "Accept-Language": "",
            Authorization: getCipherHeader(),
          },
        })
          .then((response) => {
            this.setState({ loading: false });
            if (response?.data?.Success && response.data.Data) {
              storeItem("TECHBASE_TOKEN", response.data.Data);
              storeItem("TECHBASE_HAS_VIEWED_ANNOUNCEMENTS", false);
              Promise.all([
                this.props.getProfile(this.props.data.ClientReducer),
              ]).then(() => {
                this.props.router.navigate("/dashboard");
              });
            } else {
              requestError(response.data.Data);
            }
          })
          .catch((error) => {
            toast.error(handleErrorMsg(error.response.data));
            this.setState({ loading: false });
          });
      });
    };

    render = () => {
      return (
        <WrapperComponent
          {...this.props}
          loginRequest={this.loginRequest}
          onLoadLogin={this.state.loading}
          email={this.state.email}
          password={this.state.password}
          statusModal={this.state.statusModal}
          showPassword={this.state.showPassword}
          onChangeLoginHOC={this.onChangeLoginHOC}
        />
      );
    };
  }

  const mapStateToProps = (state) => ({
    data: state,
    colors: state.ClientReducer.clientInfo.styles?.colors,
    selectedLanguage: state.ProfileReducer.profile.preferred_language,
    siteInfo: state.ClientReducer.siteInfo,
    profile: state.ProfileReducer.profile,
  });

  return connect(mapStateToProps, {
    getProfile,
    setUserProfile,
  })(WithHOC);
};

export default HOC;
