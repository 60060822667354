import React, { useState } from "react";
import { DropdownToggle, DropdownMenu, Button, UncontrolledButtonDropdown, UncontrolledTooltip } from "reactstrap";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import i18next from "i18next";
import { useNavigate } from "react-router-dom";

import { clearItem } from "utils/tokenStore";
import TechBaseModal from "components/Modal";
import TechBaseModalBody from "components/Modal/body";
import avatar1 from "assets/images/av1.png";
import { clearCart, clearUpgradeCart, clearRedemptionCart } from "reducers/ShopCart";
import { setEnableMobileMenuSmall } from "reducers/ThemeOptions";

const Details = ({ open, content, onClose }) => {
  return (
    <TechBaseModal isOpen={open} size={"md"} onClose={() => onClose()}>
      <TechBaseModalBody>
        <span>{content}</span>
      </TechBaseModalBody>
    </TechBaseModal>
  );
};

const Userbox = ({
  profile,
  history,
  clearCart,
  clearUpgradeCart,
  clearRedemptionCart,
  setEnableMobileMenuSmall
}) => {
  const [showDetails, onToggleDetail] = useState(false);
  const [selectedNotification, onChangeSelected] = useState(false);
  const [showUserBox, onToggleUserBox] = useState(false);
  const { Email, FirstName, LastName, profile_photo_url } = profile;
  const token = localStorage["IMPERSONATE_TOKEN"] || localStorage["TECHBASE_TOKEN"];
  const navigate = useNavigate()

  return (
    <>
      <div className="ml-0 pl-0 pr-0">
        <div className="widget-content p-0">
          <div className="widget-content-wrapper">
            <div className="widget-content-left">
              <UncontrolledButtonDropdown isOpen={showUserBox}>
                <DropdownToggle color="link" className="p-0" id="account" onClick={() => onToggleUserBox(!showUserBox)}>
                  <img width={42} className="rounded-circle btn-signout" src={profile_photo_url || avatar1} alt="" />
                  <FontAwesomeIcon className="ml-2 opacity-8" icon={faAngleDown} />
                  <UncontrolledTooltip target="account">
                    <p>{i18next.t("ACCOUNT")}</p>
                  </UncontrolledTooltip>
                </DropdownToggle>
                <DropdownMenu end className="rm-pointers dropdown-menu-lg p-0">
                  <div className="dropdown-menu-header" style={{ marginBottom: 0 }}>
                    <div className="dropdown-menu-header-inner bg-white">
                      <div className="menu-header-image opacity-2" />
                      <div className="menu-header-content text-left">
                        <div className="widget-content p-0">
                          <div className="widget-content-wrapper align-items-start">
                            <div className="widget-content-left mr-3">
                              <img width={42} className="rounded-circle" src={profile_photo_url || avatar1} alt="" />
                            </div>
                            <div className="widget-content-left">
                              <div className="widget-heading text-dark">{`${FirstName} ${LastName}`}</div>
                              <div className="widget-subheading text-dark opacity-8">{Email}</div>
                              <div className="mt-2">
                                <Button
                                  className="btn-pill bg-dark text-light btn-shadow btn-shine btn-logout mr-2 mb-2"
                                  color="focus"
                                  onClick={() => {
                                    onToggleUserBox(false);
                                    setEnableMobileMenuSmall(false);
                                    navigate("/dashboard/profile");
                                  }}
                                >
                                  {i18next.t("UPDATE_PROFILE")}
                                </Button>
                                <Button
                                  className="btn-pill bg-dark text-light btn-shadow btn-shine btn-logout mr-2 mb-2"
                                  color="focus"
                                  onClick={() => {
                                    clearCart();
                                    clearUpgradeCart();
                                    clearRedemptionCart();
                                    if (localStorage["IMPERSONATE_TOKEN"]) {
                                      clearItem("IMPERSONATE_TOKEN");
                                      navigate("/dashboard/member-list");
                                      window.location.reload(true);
                                    } else {
                                      clearItem("TECHBASE_TOKEN");
                                      navigate("/login");
                                    }
                                  }}
                                >
                                  {localStorage["IMPERSONATE_TOKEN"]
                                    ? i18next.t("BACK_TO_ADMIN_ACCOUNT")
                                    : i18next.t("LOGOUT")}
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>
          </div>
        </div>
      </div>
      <Details open={showDetails} content={selectedNotification?.content ?? ""} onClose={() => onToggleDetail(false)} />
    </>
  );
};

const mapStateToProps = (state) => ({
  profile: state.ProfileReducer.profile,
  clientInfo: state.ClientReducer.clientInfo
});
export default connect(mapStateToProps, { clearCart, clearUpgradeCart, clearRedemptionCart, setEnableMobileMenuSmall })(
  Userbox
);
