import React, { Fragment } from 'react'
import _ from 'lodash'
import { 
  DropdownToggle, DropdownMenu,
  Nav, NavItem,
  UncontrolledButtonDropdown 
} from 'reactstrap'
import { GlobalOutlined } from "@ant-design/icons"
import i18next from 'i18next'
import languages from './languages.png'
import { getContraColor } from 'utils'
import { applyColor } from 'utils/apply-color'
import WithHOC from './actions'

export const SUPPORTED_LANGUAGES = [
  { 
    label: 'EN',
    name: 'ENGLISH',
    value: 'en',
    id: 'en'
  },
  { 
    label: 'ZH',
    name: 'MANDARIN',
    value: 'zh',
    id: 'zh'
  },
  {
    label: 'MS',
    name: 'MALAY',
    value: 'ms',
    id: 'ms'
  }
]

const LanguageSwitcher = ({
  profile,
  selectedLanguage,
  containerStyle,
  updateClientUser,
  setUserProfile,
  from,
  colors,
  clientInfo
}) => {

  return (
    <UncontrolledButtonDropdown style={{ ... containerStyle }}>
      <DropdownToggle color="link" className={ 'd-flex align-items-center pl-0' }>
      <img
        src={languages} // Replace with your image path
        alt="Language"
        style={{
          width: "35px",
          height: "35px",
          color: getContraColor(applyColor('$primary', colors)),
        }}
      />
        {/* <GlobalOutlined style={{
          fontSize: "20px",
          color: getContraColor( applyColor( '$primary', colors ))}} />
          <img src="./languages.png" alt="languages" style={{ width: 20, height: 20 }}/>
          <span className="ml-2">Language</span> */}
      </DropdownToggle>
      <DropdownMenu end className="dropdown-menu-xl rm-pointers" style={{ minWidth: '18rem' }}>
          <div className="dropdown-menu-header mb-0">
              <div className="dropdown-menu-header-inner" style={{ padding: '0.8rem 0.5rem' }}>
                  <div className="menu-header-content text-dark">
                      <h5 className="menu-header-title">
                        { i18next.t( 'SWITCH_LANGUAGE' )}
                      </h5>
                  </div>
              </div>
          </div>
          <Nav vertical>
          { _.filter( SUPPORTED_LANGUAGES, lang => ['en', 'zh', 'ms'].includes( lang.id ) ).map((lng) => (
              <Fragment key={ lng.value }>
                <NavItem className="nav-item-divider text-align"/>
                <NavItem 
                  className={ `nav-item-btn text-center nav-update-lang` }
                  style={{ cursor: 'pointer' }}
                  onClick={ () => {
                    setUserProfile({
                      ...profile,
                      preferred_language: lng.value
                    })
                    if (profile && profile.id) {
                      updateClientUser({
                        ...profile,
                        preferred_language: lng.value
                      })
                    } else {
                      console.error('Profile is missing or incomplete');
                    }
                  }}
                >
                  { i18next.t( lng.name )}
                  {
                    selectedLanguage === lng.value && (
                      <div 
                        className="rounded-circle bg-primary ml-3" 
                        style={{ width: 10, height: 10, display: 'inline-block' }}
                      />
                    )
                  }
                </NavItem>
              </Fragment>
          ))}
        </Nav>
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  )
}

export default WithHOC( LanguageSwitcher )