export const LoginLayout = {
  type: "div",
  style: { backgroundColor: "#ffffff" },
  children: [
    {
      type: "div",
      className: "row justify-content-center align-items-center",
      style: { minHeight: "95vh", backgroundColor: "$background" },
      children: [
        {
          type: "div",
          className: "p-5",
          style: { backgroundColor: "$card", borderRadius: "10px" },
          children: [
            {
              type: "div",
              className: "text-center mb-5",
              style: { width: "100%" },
              children: [
                {
                  type: "img",
                  style: { width: "200px" },
                  altText: "client-logo"
                }
              ]
            },
            {
              type: "form",
              children: [
                {
                  type: "input",
                  rules: [{ required: true, message: "{{PLEASE_ENTER_YOUR}} {{USERNAME}}" }],
                  label: "{{USERNAME}}",
                  name: "username",
                  className: "",
                  inputConfig: {
                    placeholder: "{{ENTER}} {{USERNAME}}",
                    suffix: {
                      type: "icon",
                      antIcon: "CheckCircleOutlined",
                      style: { color: "#bbbbbb"}
                    },
                    className: "",
                    size: "large"
                  }
                },
                {
                  type: "input",
                  variant: "password",
                  rules:[{ required: true, message: "{{PLEASE_ENTER_YOUR}} {{PASSWORD}}" }],
                  label: "{{PASSWORD}}",
                  name: "password",
                  className: "",
                  inputConfig: {
                    placeholder: "{{ENTER}} {{PASSWORD}}",
                    className: "",
                    size: "large"
                  }
                },
                {
                  type: "input",
                  rules: [{ required: true, message: "{{PLEASE_ENTER}} {{CAPTCHA}}" }],
                  label: "{{VERIFICATION_CODE}} ",
                  name: "captcha",
                  className: "",
                  inputConfig: {
                    suffix: {
                      type: "captcha",
                      height: 39,
                      width: 110,
                      style: { borderRadius: 20, overflow: "hidden" },
                      imageStyle: { height: 27, width: 80 },
                      canRefresh: true
                    },
                    className: "",
                    size: "large"
                  }
                },
                {
                  type: "button",
                  size: "large",
                  htmlType: "submit",
                  buttonType: "text",
                  className: "custom-color my-3",
                  style: { backgroundColor: "$quaternary", color: "!quaternary", width: "100%" },
                  children: [
                    {
                      type: "text",
                      content: "{{LOGIN}}"
                    }
                  ]
                }
              ]
            },
            {
              type: "div",
              className: "my-4",
              children: [
                {
                  type: "text",
                  content: "<div class=\"text-center\"><a style=\"color: $quaternary;\" href=\"/forgot-password\">{{FORGOT_PASSWORD}}</a></div>"
                },
                {
                  type: "text",
                  content: {
                    en: "<div class=\"text-center\">Not A Member? <a style=\"color: $quaternary;\" href=\"/register\">Sign Up Now</a></div>"
                  }
                }
              ]
            }
          ]
        }
      ]
    },
    {
      type: "footer",
      style: { minHeight: "5vh" }
    }
  ]
}