import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { addLoadingContent, removeLoadingContent } from "reducers/LoadingOverlay";
import { Get, Post } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";
import { handleErrorMsg } from "utils";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      sponsor: { MemberId: "000001", Fullname: "Papan Memorial Admin" },
      registrationFee: 50,
      bankOptions: [],
      stateOptions: [],
      countryOptions: [],
      paymentOptions: [],
      documentOptions: [],
      maritalStatusOptions: [],
      customerOptions: [
        { value: false, label: "Customer" },
        { value: true, label: "Corporate" }
      ],
      genderOptions: [
        { value: "M", label: "Male" },
        { value: "F", label: "Female" }
      ],
      decisionOptions: [
        { value: true, label: "Yes" },
        { value: false, label: "No" }
      ]
    };

    load = (param) => {
      if (param) {
        this.props.addLoadingContent();
      } else {
        this.props.removeLoadingContent();
      }
    };

    getCountryOptions = () =>
      Get(`/util/viewCountryList`, this.getCountryOptionsSuccess, this.getCountryOptionsError, this.load, true);
    getCountryOptionsSuccess = (payload) => this.setState({ countryOptions: payload.Data });
    getCountryOptionsError = (error) => {};

    getStateOptions = () =>
      Get(`/util/viewStateList`, this.getStateOptionsSuccess, this.getStateOptionsError, this.load, true);
    getStateOptionsSuccess = (payload) => this.setState({ stateOptions: payload.Data });
    getStateOptionsError = (error) => {};

    getBankOptions = () =>
      Get(`/util/viewBankList`, this.getBankOptionsSuccess, this.getBankOptionsError, this.load, true);
    getBankOptionsSuccess = (payload) => this.setState({ bankOptions: payload.Data });
    getBankOptionsError = (error) => {};

    getRegistrationFee = () =>
      Get(`/util/getRegistrationFees`, this.getRegistrationFeeSuccess, this.getRegistrationFeeError, this.load, true);
    getRegistrationFeeSuccess = (payload) => this.setState({ registrationFee: payload?.Data?.Price ?? 50 });
    getRegistrationFeeError = (error) => {};

    getSponsor = (sponsorID) =>
      Post(
        `/member/getMemberName`,
        { memberID: sponsorID },
        (payload) => this.getSponsorSuccess(payload, sponsorID),
        this.getSponsorError,
        this.load,
        true
      );
    getSponsorSuccess = (payload, sponsorID) =>
      this.setState({ sponsor: { MemberId: sponsorID, Fullname: payload.Data } });
    getSponsorError = (error) => {};

    getParamOptions = (key, category) =>
      Post(
        `/util/getParameterValue`,
        { category: category },
        (payload) => this.getParamOptionsSuccess(payload, key),
        this.getParamOptionsError,
        this.load,
        true
      );
    getParamOptionsSuccess = (payload, key) => this.setState({ [key]: payload.Data });
    getParamOptionsError = (error) => {};

    registerMember = (data) =>
      Post(
        `/member/insertMember`,
        data,
        (payload) => this.registerMemberSuccess(payload, data),
        this.registerMemberError,
        this.load,
        true
      );
    registerMemberSuccess = (payload, data) => {
      requestSuccess("Member created successfully");
      if (data.role === "M") {
        this.props.router.navigate("/login");
      } else if (data.role === "C") {
        if(!_.isNil(this.props.onSuccessCreate)){
          this.props.onSuccessCreate(payload.Data?.MemberId)
        } else {
          this.props.router.navigate("/dashboard/mall");
        }
      }
    };
    registerMemberError = (error) => requestError(handleErrorMsg(error));

    render = () => {
      console.log(this.props)
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          getSponsor={this.getSponsor}
          registerMember={this.registerMember}
          getRegistrationFee={this.getRegistrationFee}
          getCountryOptions={this.getCountryOptions}
          getParamOptions={this.getParamOptions}
          getStateOptions={this.getStateOptions}
          getBankOptions={this.getBankOptions}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({
    selectedLanguage: state.ProfileReducer.profile.preferred_language
  });
  return connect(mapStateToProps, { addLoadingContent, removeLoadingContent })(WithHOC);
};

export default HOC;
