import React, { Component } from "react"
import { connect } from "react-redux"
import _ from "lodash"

import { Put } from 'utils/axios'
import { processSite } from 'utils/host'
import { handleErrorMsg, getSelectedLanguageContent } from "utils"
import { requestError, requestSuccess } from 'utils/requestHandler'
import { addLoadingContent, removeLoadingContent } from 'reducers/LoadingOverlay'

const HOC = WrapperComponent => {
  class WithHOC extends Component {
    state = {
      token: ""
    }
    load = param => {
      if( param ){
        this.props.addLoadingContent()
      } else {
        this.props.removeLoadingContent()
      }
    }

    componentDidMount = () => {
      if( window.location.hash.includes( 'token=' ) ){
        this.setState({ token: window.location.hash.split('token=')[1] })
      } else {
        this.setState({ token: "" })
      }
    }

    onSubmitForm = formData => {
      if (_.isEmpty(this.state.token)) {
        requestError( getSelectedLanguageContent("{{INVALID_TOKEN}}")  )
      }

      this.changeSecurityPin( { security_pin: formData.security_pin }, this.state.token )
    }

    changeSecurityPin = ( data, token ) => Put(
      `/reset_forgot_security_pin`,
      data,
      this.changeSecurityPinSuccess,
      this.changeSecurityPinError,
      this.load,
      false,
      token
    )
    changeSecurityPinSuccess = () => {
      this.props.router?.navigate( "/dashboard/update-profile" )
      requestSuccess( getSelectedLanguageContent("{{SECURITY_PIN_HAS_BEEN_RESET}}") )
    }
    changeSecurityPinError = error => requestError( handleErrorMsg( error ) )

    render = () => {
      return (
        <WrapperComponent 
          {...this.props} 
          token={ this.state.token }
          onSubmitForm={ this.onSubmitForm } />
      )
    }
  }

  const mapStateToProps = state => ({ 
    data: state,
    colors: state.ClientReducer.clientInfo.styles?.colors,
    selectedLanguage: state.ProfileReducer.profile.preferred_language,
    clientInfo: state.ClientReducer.clientInfo
  })

  return connect( mapStateToProps, { addLoadingContent, removeLoadingContent } )( WithHOC )
}

export default HOC
