import React from "react";
import _ from "lodash";
import { Result, ConfigProvider } from "antd";

import { applyColor } from "utils/apply-color";

const TechBaseResult = (props) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: applyColor(`$primary`)
        }
      }}
    >
      <Result {...props} />
    </ConfigProvider>
  );
};

export default TechBaseResult;
