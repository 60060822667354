import React from "react"
import { Card } from "antd"
import _ from "lodash"

import { applyColor } from "utils/apply-color"

const TechBaseCard = (props) => {
  let { style = {}, children, headStyle = {}, bodyStyle = {} } = props

  return (
    <Card
      {..._.omit(props, ["style", "headStyle", "bodyStyle", "children"])}
      style={applyColor(style, props.colors)}
      headStyle={applyColor(headStyle, props.colors)}
      bodyStyle={applyColor(bodyStyle, props.colors)}
    >
      {children}
    </Card>
  )
}

export default TechBaseCard
