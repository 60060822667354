import _ from "lodash"
import React from "react"
import { FilePond, registerPlugin } from "react-filepond"
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type"
import FilePondPluginPdfPreview from "filepond-plugin-pdf-preview"
import { browserName } from "react-device-detect"

import "filepond/dist/filepond.min.css"

if (browserName === "Safari") {
  registerPlugin(FilePondPluginFileValidateType)
} else {
  registerPlugin(FilePondPluginPdfPreview, FilePondPluginFileValidateType)
}

const TechBaseFilePond = (props) => {
  const { value, acceptedFileTypes, allowMultiple=false, onChange } = props

  const processFile = (fileItems) => {
    if (fileItems && fileItems.length > 0) {
      const reader = new FileReader()
      reader.onload = () => {
        onChange({
          base64: reader.result,
          file_name: fileItems[0].filename,
          files: fileItems
        })
      }
      reader.readAsDataURL(fileItems[0].file)
    }
  }

  return (
    <FilePond
      files={value?.files}
      allowMultiple={allowMultiple}
      acceptedFileTypes={acceptedFileTypes}
      onupdatefiles={(fileItems) => processFile(fileItems)}
    />
  )
}

export default TechBaseFilePond
